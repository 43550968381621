import React from 'react';
import Splash from './components/Splash';

function App() {
  return (
    <Splash />
  );
}

export default App;
